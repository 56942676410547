import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styles from "./blog-list.module.css"
import Pager from "../components/pager"

const BlogList = ({ data, location, pageContext }) => {
  const siteDescription = data.site.siteMetadata.description
  const posts = data.allMdx.edges
  const { currentPage, numPages } = pageContext

  return (
    <Layout location={location} title="Latest posts">
      <SEO title="Latest posts" description={siteDescription} />
      {posts.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug
        return (
          <article key={node.fields.slug} className={styles.article}>
            <header className={styles.titleHolder}>
              <h2 className={styles.title}>
                <Link className={styles.titleLink} to={node.fields.slug}>
                  {title}
                </Link>
              </h2>
            </header>
            <div className={styles.imageHolder}>
              <Link className={styles.imageLink} to={node.fields.slug}>
                <img src={`/${node.frontmatter.image}`} alt={node.frontmatter.title} />
              </Link>
            </div>
            <div className={styles.lead}>
              <section>
                {node.frontmatter.description || node.excerpt}
                <Link className={styles.button} to={node.fields.slug}>
                  VIEW POST
                </Link>
              </section>
            </div>
          </article>
        )
      })}
      <Pager currentPage={currentPage} numPages={numPages} />
      <br />
    </Layout>
  )
}

export default BlogList

export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!){
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            image 
            description
          }
        }
      }
    }
  }
`
