import { Link } from "gatsby"
import React from "react"
import styles from "./pager.module.css"

const Pager = ({ currentPage, numPages }) => {
  const isFirst = isNaN(currentPage) || currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? "/" : (currentPage - 1).toString()
  const nextPage = (currentPage + 1).toString()
  return (
    <div className={styles.pager}>
      {!isFirst && (
        <Link to={`/${prevPage}`} rel="prev">
          ← Previous Page
        </Link>
      )}
      {
        Array.from({ length: numPages }, (_, i) => (
          <Link
            key={`pagination-number${i + 1}`}
            to={i === 0 ? "/" : `/${i + 1}/`}
            className={currentPage === i + 1 ? styles.currentPageLink : styles.pageLink}
          >
            {i + 1}
          </Link>
        ))
      }
      {!isLast && (
        <Link to={`/${nextPage}/`} rel="next">
          Next Page →
        </Link>
      )}
    </div>
  )
}

export default Pager
